// Import Element Theme
@import '@simpl/element-theme/src/theme';

// Import Element Components
@import '@simpl/element-ng/simpl-element-ng';

// For code highlighting
@import '~highlight.js/styles/atom-one-dark.css';

.badge {
  color: white;
  background-color: #008080b4;
  margin-left: 1px !important;
}

si-status-bar {
  overflow: hidden;
}

si-split-part {
  border: 1px solid #efefef;
}

.si-split-part-header.is-collapsed {
  border: 0;
}

.si-split-gutter {
  width: 10px;
}

si-status-bar {
  margin-bottom: 0 !important;
}

.logDialog {
  width: 80vw;
  height: 80vh;

  .p-dialog-content {
    overflow: hidden;
  }
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//////////////////////////////////////////////////////
// For Syntax highlighter
pre {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  word-wrap: break-word;
  height: 100%;

  code {
    flex: 1;
    line-height: 1.5em;
    font-size: 14px;
    min-height: 100%;
    padding: 1em 1.2em;

    &.hljs-line-numbers {
      padding: 0;
    }
  }
}

// The below style is taken from simpl element itself, might be little different than what we require
.ngx-datatable.table-element .datatable-header .resizeable:hover .resize-handle {
  background-color: #f0f2f5;
  width: 2px;
  border-radius: 2px;
  padding: 0 2px;
}


/** Line numbers styles */
.hljs {
  width: 100%;
  height: 100%;
  // margin: 0 1em;
  padding: 10px;
  // border: 1px solid gray;
  // border-radius: 4px;
  transition: border ease 1s;
}

.hljs-ln {
  padding: 8px 0;

  tr {
    &:first-child td {
      padding-top: 8px !important;
    }

    &:last-child td {
      padding-bottom: 8px !important;
    }
  }
}

/* for block of numbers */
td.hljs-ln-numbers {
  user-select: none;
  text-align: center;
  border-right: 1px solid gray;
  vertical-align: top;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

  /* for block of code */
td.hljs-ln-code {
  padding-left: 8px !important;
}

.overlay-disable {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1050;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-spinner {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 2% 2% 0%;
  background-color: #ffffff;
}

.progress-bar-container {
  padding: 2% 5% 3%;
  width: 100%;
}

//////////////////////////////////////////////////////

